import React from "react";
import Navbar from "./components/navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

// import io from 'socket.io-client';

import Home from "./pages";
import Copilot from "./pages/copilot";
import Control from "./pages/control";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Main from "./pages/main";
import Logout from "./pages/logout";
import Robots from "./pages/robots";
import Api from "./pages/api";
import Privacy from "./pages/privacy";

// const socket = io.connect('http://localhost:3000');

function App() {

    return (
        <Router>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/copilot" element={<Copilot />} />
                <Route path="/control" element={<Control />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/main" element={<Main />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/robots" element={<Robots />} />
                <Route path="/api" element={<Api />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </Router>
    );
}

export default App;
