import React, { useEffect, useState }  from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { NavLink, useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';

const Navbar = () => {
  const navigate = useNavigate();
  const [uid, setUid] = useState(null);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
          } else {
            console.log("user is logged out")
            setUid(null)
            navigate("/")
          }
        });

  }, [])

  return (

  <nav className="navbar navbar-dark bg-dark  navbar-expand-lg">
    <a className="navbar-brand brand" href="/">&nbsp; ROBOTICS.DEV</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarTogglerDemo01">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

        <li className="nav-item px-2">
          {uid === null ? "" : <NavLink to="/main" onClick={handleNavCollapse}>Home</NavLink>}
        </li>
        <li className="nav-item px-2">
          {uid === null ? "" : <NavLink to="/robots" onClick={handleNavCollapse}>Robots</NavLink>}
        </li>
        <li className="nav-item px-2">
          {uid === null ? "" : <NavLink to="/control" onClick={handleNavCollapse}>Remote Control</NavLink>}
        </li>
        <li className="nav-item px-2">
          {uid === null ? "" : <NavLink to="/api" onClick={handleNavCollapse}>API</NavLink>}
        </li>
        <li className="nav-item px-2">
          {uid === null ? "" : <NavLink to="/logout" onClick={handleNavCollapse}>Logout</NavLink>}
        </li>

      </ul>
    </div>

  </nav>


  )
}

export default Navbar;
