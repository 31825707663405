import React, { useEffect, useState }  from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from '../firebase';
import "./../App.css";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-WQXKE0M3J5";

function Main() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [uid, setUid] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: "/main", title: "Main Page" });

      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUid(user.uid);
            setName(user.displayName);
            // mqttConnect(user.uid);
            // getUserSettings(user.uid);
          } else {
            console.log("user is logged out")
            navigate("/")
          }
        });

  }, [])

  const handleLogout = () => {
      signOut(auth).then(() => {
        navigate("/");
        console.log("Signed out successfully")
      }).catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="App">
      <header className="Content-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img alt="robot" src={`https://robohash.org/${uid}?size=200x200`}/>
              <h1 className="brand">
                Welcome {name}
              </h1>
              <p>
                Checkout our new <a href="https://chatgpt.com/g/g-6745fb7059388191ab41ddf36ef7e9ea-robotics" target="_new">Robotics GPT</a> on OpenAI!
              </p>
            </div>
            <div className="col-sm-6">

            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Main;
